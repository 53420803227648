*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-full {
  bottom: 100%;
}

.left-0 {
  left: 0;
}

.left-8 {
  left: 2rem;
}

.start-0 {
  inset-inline-start: 0;
}

.top-0 {
  top: 0;
}

.top-full {
  top: 100%;
}

.isolate {
  isolation: isolate;
}

.z-50 {
  z-index: 50;
}

.z-\[1\] {
  z-index: 1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.m-auto {
  margin: auto;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.\!mb-8 {
  margin-bottom: 2rem !important;
}

.-ml-12 {
  margin-left: -3rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-0 {
  margin-left: 0;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ms-2 {
  margin-inline-start: .5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.w-3 {
  width: .75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-\[24rem\] {
  width: 24rem;
}

.w-\[48rem\] {
  width: 48rem;
}

.w-\[calc\(100\%-2rem\)\] {
  width: calc(100% - 2rem);
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-none {
  max-width: none;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.columns-1 {
  columns: 1;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[2rem\] {
  border-radius: 2rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-slate-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-sky-400 {
  --tw-gradient-from: #38bdf8 var(--tw-gradient-from-position);
  --tw-gradient-to: #38bdf800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow-400 {
  --tw-gradient-from: #facc15 var(--tw-gradient-from-position);
  --tw-gradient-to: #facc1500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-amber-600 {
  --tw-gradient-to: #d97706 var(--tw-gradient-to-position);
}

.to-green-600 {
  --tw-gradient-to: #16a34a var(--tw-gradient-to-position);
}

.bg-clip-text {
  background-clip: text;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-4 {
  padding: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.ps-6 {
  padding-inline-start: 1.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.\!text-left {
  text-align: left !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.font-\[sans-serif\] {
  font-family: sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.\!text-slate-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow-\[3px_3px_6px_0px_rgba\(0\,0\,0\,0\.1\)\] {
  --tw-shadow: 3px 3px 6px 0px #0000001a;
  --tw-shadow-colored: 3px 3px 6px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-gray-400\/10 {
  --tw-ring-color: #9ca3af1a;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[height\] {
  transition-property: height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

:root {
  --has-banner: 1;
  --banner-height: 50px;
  --header-height: 80px;
  --header-min-width: 740px;
  --content-radius: 22px;
}

::selection {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.border-radius {
  border-radius: var(--content-radius);
}

header + .container {
  margin-top: var(--header-height);
}

div.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

h1.page-title + div.container {
  margin-top: -4rem;
}

h1.page-title + .container {
  padding-top: 2rem;
}

.page-title {
  letter-spacing: -.025em;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--header-height);
  max-width: 1280px;
  padding: 4rem 2rem;
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1;
}

.page-title + .page-subtitle {
  letter-spacing: -.025em;
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
  max-width: 1280px;
  margin-top: -1rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.5rem;
}

div.feature-list {
  max-width: 42rem;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 1024px) {
  div.feature-list {
    max-width: 56rem;
  }
}

dl.feature-list {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.5rem 2rem;
  max-width: 36rem;
  display: grid;
}

@media (width >= 1024px) {
  dl.feature-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 4rem;
    max-width: none;
  }
}

dl.feature-list > .item {
  padding-left: 4rem;
  position: relative;
}

dl.feature-list > .item > dt.title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

dl.feature-list > .item > dd.desc {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: .5rem;
  font-size: 1rem;
  line-height: 1.75rem;
}

dl.feature-list > .item > dt.title > .icon {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

dl.feature-list > .item > dt.title > .icon svg {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  width: 1.5rem;
  height: 1.5rem;
}

ol.checklist {
  --tw-border-opacity: 1;
  border-inline-start-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  position: relative;
}

ol.checklist > li {
  margin-bottom: 2.5rem;
  margin-inline-start: 1.5rem;
}

ol.checklist > li > .icon {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: .25rem;
  display: flex;
  position: absolute;
  inset-inline-start: -.625rem;
}

ol.checklist > li > h3.title {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  margin-bottom: .375rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}

ol.checklist > li > p.desc {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

div.banner {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (width >= 640px) {
  div.banner {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

div.banner-container {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #334155 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0;
  padding: 4rem;
}

@media (width >= 640px) {
  div.banner-container {
    border-radius: 22px;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (width >= 1024px) {
  div.banner-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

div.banner > h2, div.banner h2.banner-title {
  text-align: center;
  letter-spacing: -.025em;
  max-width: 42rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

div.banner > h2 + p.banner-subtitle, div.banner h2.banner-title + p.banner-subtitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  margin-top: -3.5rem;
  margin-bottom: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (width >= 640px) {
  div.banner > h2 + p.banner-subtitle, div.banner h2.banner-title + p.banner-subtitle {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

div.banner > h2 + p.banner-desc, div.banner h2.banner-title + p.banner-desc {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
  margin-top: -2rem;
  margin-bottom: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (width >= 640px) {
  div.banner > h2 + p.banner-desc, div.banner h2.banner-title + p.banner-desc {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

div.section {
  padding: 4rem 2rem;
}

div.section.condensed {
  padding: 2rem;
}

@media (width >= 1024px) {
  div.section.wide {
    padding-left: 0;
    padding-right: 0;
  }
}

div.section > h2, div.section h2.section-title {
  text-align: center;
  letter-spacing: -.025em;
  max-width: 42rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
}

div.section > h2 + p.section-subtitle, div.section h2.section-title + p.section-subtitle {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: -3.5rem;
  margin-bottom: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (width >= 640px) {
  div.section > h2 + p.section-subtitle, div.section h2.section-title + p.section-subtitle {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

div.section > h2 + p.section-desc, div.section h2.section-title + p.section-desc {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  margin-top: -2rem;
  margin-bottom: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (width >= 640px) {
  div.section > h2 + p.section-desc, div.section h2.section-title + p.section-desc {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

div.section > h2 + div.content, div.section h2.section-title + div.content, div.section > p.section-subtitle + div.content, div.section > p.section-desc + div.content {
  padding-top: 2rem;
}

div.section > div.content {
  margin-left: auto;
  margin-right: auto;
}

div.section > div.content p, div.section > div.content div.p {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
  line-height: 1.75rem;
}

div.section > div.content p + p, div.section > div.content p + div.p, div.section > div.content div.p + p, div.section > div.content div.p + div.p {
  margin-top: 1rem;
}

.visibility-visible {
  visibility: visible;
}

.visibility-hidden {
  visibility: hidden;
}

a {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
  text-underline-offset: 4px;
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

a:hover, a.rounded, a.rounded:hover {
  text-decoration-line: none;
}

button, a.btn {
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  border-radius: .375rem;
  margin: .25rem .25rem .25rem 0;
  padding: .625rem 1.25rem;
  font-weight: 500;
  text-decoration-line: none;
  display: inline-block;
}

button:hover, a.btn:hover {
  text-decoration-line: none;
}

button.primary, a.btn.primary {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

button.primary:hover, a.btn.primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

button.primary:focus, a.btn.primary:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

button.primary.arrow-link, a.btn.primary.arrow-link:after, button.black.arrow-link, a.btn.black.arrow-link:after {
  background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 14'><path fill='%23fff' stroke='none' d='M8.136 0L15 7l-6.864 7-1.38-1.37L11.3 8.063H0V6.112l11.475-.001-4.718-4.74L8.136 0z' /></svg>");
}

button.black, a.btn.black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  border-width: 2px;
  border-color: #0000;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

button.black:hover, a.btn.black:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

button.black:focus-visible, a.btn.black:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button.black, a.btn.white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  border-width: 2px;
  border-color: #0000;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

button.black:hover, a.btn.white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

button.black:focus-visible, a.btn.white:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button.stroked, a.btn.stroked {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

button.stroked:hover, a.btn.stroked:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

button.stroked:focus-visible, a.btn.stroked:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

button.circle, a.btn.circle {
  border-radius: 9999px;
}

a.arrow-link {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

a.arrow-link:after {
  content: "";
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 14'><path fill='currentColor' stroke='none' d='M8.136 0L15 7l-6.864 7-1.38-1.37L11.3 8.063H0V6.112l11.475-.001-4.718-4.74L8.136 0z' /></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  margin-left: 8px;
  margin-right: -20px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

a.arrow-link.ext:after {
  transform: rotate(-45deg);
}

a.arrow-link:hover:after {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a.btn.arrow-link {
  align-items: center;
  column-gap: .25rem;
  padding-right: 32px;
  display: inline-flex;
}

.scroll-margin-top {
  scroll-margin-top: calc(var(--header-height)  + var(--banner-height));
}

.scroll-margin-top-sm {
  scroll-margin-top: calc(var(--header-height)  + var(--banner-height)  + 1rem);
}

.scroll-margin-top-md {
  scroll-margin-top: calc(var(--header-height)  + var(--banner-height)  + 2rem);
}

.scroll-margin-top-lg {
  scroll-margin-top: calc(var(--header-height)  + var(--banner-height)  + 3rem);
}

.header-margin-top {
  margin-top: var(--header-height);
}

#contact [role="alert"] {
  margin-bottom: 2rem;
}

#contact [role="alert"].error {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

#contact [role="alert"].error a {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

#contact [role="alert"].success {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

#contact [role="alert"].success a {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

[role="alert"] {
  margin-bottom: 2rem;
}

[role="alert"].error {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

[role="alert"].error a {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

[role="alert"].success {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

[role="alert"].success a {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.faq .hs-accordion-group {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
  margin-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 0;
}

.faq .hs-accordion-group .hs-accordion {
  margin-left: -1.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.faq .hs-accordion-group .hs-accordion.active {
  padding-bottom: 1rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle {
  text-align: start;
  border-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  column-gap: .75rem;
  width: 100%;
  margin-right: 0;
  padding-bottom: .75rem;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: inline-flex;
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle:disabled {
  pointer-events: none;
  opacity: .5;
}

.faq .hs-accordion-group .hs-accordion .hs-accordion-toggle svg {
  min-width: 24px;
  min-height: 24px;
}

.faq .hs-accordion-group .hs-accordion.active .hs-accordion-toggle {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.faq .hs-accordion-group .hs-accordion.active {
  --tw-border-opacity: 1;
  border-left-width: 2px;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.faq .hs-accordion-content {
  padding-right: 2.5rem;
}

.placeholder\:text-transparent::placeholder {
  color: #0000;
}

.before\:me-6:before {
  content: var(--tw-content);
  margin-inline-end: 1.5rem;
}

.before\:flex-\[1_1_0\%\]:before {
  content: var(--tw-content);
  flex: 1;
}

.before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.before\:border-gray-200:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.after\:ms-6:after {
  content: var(--tw-content);
  margin-inline-start: 1.5rem;
}

.after\:flex-\[1_1_0\%\]:after {
  content: var(--tw-content);
  flex: 1;
}

.after\:border-t:after {
  content: var(--tw-content);
  border-top-width: 1px;
}

.after\:border-gray-200:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.autofill\:pb-2:autofill {
  padding-bottom: .5rem;
}

.autofill\:pt-6:autofill {
  padding-top: 1.5rem;
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:grayscale-0:hover {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.focus\:pb-2:focus {
  padding-bottom: .5rem;
}

.focus\:pt-6:focus {
  padding-top: 1.5rem;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.peer:focus ~ .peer-focus\:-translate-y-2\.5 {
  --tw-translate-y: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.peer:focus ~ .peer-focus\:text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.peer:disabled ~ .peer-disabled\:pointer-events-none {
  pointer-events: none;
}

.peer:disabled ~ .peer-disabled\:opacity-50 {
  opacity: .5;
}

.peer:not(:placeholder-shown) ~ .peer-\[\:not\(\:placeholder-shown\)\]\:-translate-y-2\.5 {
  --tw-translate-y: -.625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:not(:placeholder-shown) ~ .peer-\[\:not\(\:placeholder-shown\)\]\:text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.peer:not(:placeholder-shown) ~ .peer-\[\:not\(\:placeholder-shown\)\]\:text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.hs-accordion.active.hs-accordion-active\:block {
  display: block;
}

.hs-accordion.active.hs-accordion-active\:hidden {
  display: none;
}

.hs-accordion.active.hs-accordion-active\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hs-accordion.active > .hs-accordion-toggle .hs-accordion-active\:block {
  display: block;
}

.hs-accordion.active > .hs-accordion-toggle .hs-accordion-active\:hidden {
  display: none;
}

.hs-accordion.active > .hs-accordion-toggle .hs-accordion-active\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hs-accordion.active > .hs-accordion-toggle.hs-accordion-active\:block {
  display: block;
}

.hs-accordion.active > .hs-accordion-toggle.hs-accordion-active\:hidden {
  display: none;
}

.hs-accordion.active > .hs-accordion-toggle.hs-accordion-active\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:w-\[40rem\] {
    width: 40rem;
  }

  .sm\:w-\[57rem\] {
    width: 57rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .sm\:pt-32 {
    padding-top: 8rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 768px) {
  .md\:w-min {
    width: min-content;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }
}

@media (width >= 1024px) {
  .lg\:sticky {
    position: sticky;
  }

  .lg\:top-24 {
    top: 6rem;
  }

  .lg\:top-8 {
    top: 2rem;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:columns-2 {
    columns: 2;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:gap-x-8 {
    column-gap: 2rem;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (width >= 1280px) {
  .xl\:columns-3 {
    columns: 3;
  }

  .xl\:gap-8 {
    gap: 2rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }

  .dark\:bg-slate-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  }

  .dark\:bg-opacity-80 {
    --tw-bg-opacity: .8;
  }

  .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }
}

.\[\&\:not\(\:placeholder-shown\)\]\:pb-2:not(:placeholder-shown) {
  padding-bottom: .5rem;
}

.\[\&\:not\(\:placeholder-shown\)\]\:pt-6:not(:placeholder-shown) {
  padding-top: 1.5rem;
}

#header-banner {
  height: var(--banner-height);
  z-index: 50;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
  width: 100%;
  position: fixed;
  top: 0;
}

#header-banner .content {
  line-height: var(--banner-height);
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 600;
}

#header-banner a {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
  text-decoration-line: none;
}

#header-banner a:hover {
  text-decoration-line: underline;
}

header {
  min-height: var(--header-height);
  top: calc(var(--has-banner) * var(--banner-height));
  z-index: 40;
  -webkit-user-select: none;
  user-select: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  justify-content: space-between;
  width: 100%;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
  position: fixed;
}

header a {
  color: inherit;
  text-decoration-line: none;
}

header a:hover {
  text-decoration-line: none;
}

header.scrolled {
  --tw-shadow: 0 8px 24px -12px #0003;
  --tw-shadow-colored: 0 8px 24px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

header nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 85rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

header ul.dropdown-menu {
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

header ul.dropdown-menu > li {
  display: flex;
}

header ul.dropdown-menu > li + li {
  margin-top: 0;
}

header ul.dropdown-menu > li > a {
  white-space: nowrap;
  text-wrap: nowrap;
  color: inherit;
  border-radius: .375rem;
  width: 100%;
  padding: 1rem 2rem 1rem 1rem;
}

header ul.dropdown-menu > li > a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.menu-btn {
  content: "";
  cursor: pointer;
  background: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='currentColor' stroke='none'><path d='M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z'/></svg>") no-repeat;
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
}

@media (width >= 768px) {
  .menu-btn {
    display: none;
  }
}

.menu-btn.open {
  background: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='currentColor' stroke='none'><path d='m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z'/></svg>") no-repeat;
}

.expand-btn:after {
  content: "";
  opacity: .75;
  background: url("data:image/svg+xml; utf8, <svg class='ms-1 flex-shrink-0 size-4' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='m6 9 6 6 6-6'></path></svg>") no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  transition: all .25s;
  display: inline-block;
  transform: rotate(0);
}

.expand-btn.open:after {
  transform: rotate(180deg);
}

.menu-items {
  align-self: stretch;
  align-items: center;
  display: flex;
}

.menu-items::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.menu-items::-webkit-scrollbar {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  width: 6px;
}

.menu-items::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

@media (width <= 767px) {
  .menu-items {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    scrollbar-gutter: stable;
    left: -100vh;
    top: calc(var(--header-height)  + var(--has-banner) * var(--banner-height));
    width: 20rem;
    height: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    transition: left .3s ease-out;
    display: block;
    position: fixed;
    overflow-y: auto;
    height: calc(100vh - var(--header-height)  - var(--has-banner) * var(--banner-height)) !important;
  }

  .menu-items.open {
    left: 0;
  }
}

.menu-items > li {
  display: block;
}

@media (width >= 640px) {
  .menu-items > li {
    align-self: stretch;
    align-items: center;
    display: flex;
  }
}

.menu-items > li > a {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width >= 640px) {
  .menu-items > li > a {
    align-self: stretch;
  }
}

.menu-items > li > .menu-item {
  padding-left: .75rem;
  padding-right: .75rem;
  display: flex;
}

@media (width <= 767px) {
  .menu-items > li > .menu-item {
    padding: 1rem;
  }

  .menu-items li a {
    padding: 10px 1rem;
  }
}

.dropdown {
  position: relative;
}

@media (width >= 768px) {
  .dropdown:hover > .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }

  .dropdown:hover > .expand-btn:after {
    transform: rotate(180deg);
  }

  .dropdown .dropdown-right .expand-btn:after, .dropdown .dropdown-left .expand-btn:after {
    right: .625rem;
  }
}

.dropdown-menu {
  white-space: nowrap;
  text-wrap: nowrap;
  width: fit-content;
}

@media (width >= 768px) {
  .dropdown-menu {
    opacity: 0;
    visibility: hidden;
    top: var(--header-height);
    z-index: 10;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 24px 24px 0 #0003;
    --tw-shadow-colored: 0 24px 24px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transition: all .4s;
    position: absolute;
    right: 0;
  }
}

@media (width <= 767px) {
  .dropdown-menu {
    width: 100%;
    padding-left: 1rem;
    display: none;
    position: relative;
    top: 0;
    overflow: hidden;
  }
}

#main-menu {
  flex-direction: row;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 2rem;
  display: flex;
}

#main-menu a.menu-item {
  white-space: nowrap;
  text-wrap: nowrap;
  font-weight: 500;
}

#main-menu a.menu-item:hover {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

#main-menu a.menu-item {
  position: relative;
}

#main-menu a.menu-item.active {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
  font-weight: 500;
}

@media (width <= 767px) {
  .overlay {
    z-index: 10;
    visibility: hidden;
    background-color: #0000;
    transition: background-color .3s ease-in-out, visibility .3s ease-in-out;
    position: fixed;
    inset: 0;
  }

  .overlay.overlay--active {
    visibility: visible;
    background-color: #00000080;
  }

  .overflow {
    overflow: hidden;
  }
}

#footer {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #020617 var(--tw-gradient-from-position);
  --tw-gradient-to: #0f172a var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  margin-top: 4rem;
}

#footer a {
  color: inherit;
  text-decoration-line: none;
}

#footer a:hover {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

#footer .title {
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

@media (width >= 768px) {
  #footer .title {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1280px) {
  #footer .title {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

#footer p {
  line-height: 2;
}
/*# sourceMappingURL=index.02c84a4b.css.map */
